// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
ActiveStorage.start()

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector('#intgame') != null) {
    const gameBlock = document.querySelector('#intgame'),
    gameCards = gameBlock.querySelectorAll('.game-card'),
    tumbler = gameBlock.querySelector('.tumbler > input[type="checkbox"]');




    tumbler.addEventListener('change', (e) => {
        if (tumbler.checked) {
            ym(94832002,'reachGoal','TUMBLER');
            gameCards.forEach(card => {
                card.classList.add('opened')
            })


        } else {
            gameCards.forEach(card => {
                card.classList.remove('opened')
            })
        }
    })


    gameCards.forEach(card => {
        card.addEventListener('click', (e) => {
            card.classList.toggle('opened')

            if (card.classList.contains('opened')) {
                if (e.target.parentNode.textContent.includes('Какая самая частая причина боли в горле?')) {
                  ym(94832002,'reachGoal','button_1');
                } else if (e.target.parentNode.textContent.includes('Если к вам обратился покупатель с болью в горле, следует ли ему рекомендовать обратиться к врачу?')) {
                  ym(94832002,'reachGoal','button_2');
                } else if (e.target.parentNode.textContent.includes('Может ли боль в горле привести к хроническим процессам в ротоглотке и рецидивам заболеваний?')) {
                  ym(94832002,'reachGoal','button_3');
                } else if (e.target.parentNode.textContent.includes('Чем антибиотик тиротрицин, входящий в состав Лоротрицин®- Алиум отличается от грамицидин?')) {
                  ym(94832002,'reachGoal','button_4');
                } else if (e.target.parentNode.textContent.includes('Что входит в состав таблеток для рассасывания Лоротрицин® - Алиум?')) {
                  ym(94832002,'reachGoal','button_5');
                } else if (e.target.parentNode.textContent.includes('Каким фармацевтическим действием обладает бензалкония хлорид?')) {
                  ym(94832002,'reachGoal','button_6');
                } else if (e.target.parentNode.textContent.includes('Какая схема приема таблеток для рассасывания Лоротрицин® - Алиум?')) {
                  ym(94832002,'reachGoal','button_7');
                } else if (e.target.parentNode.textContent.includes('Через сколько минут Лоротрицин® - Алиум может обезболить больное горло?')) {
                  ym(94832002,'reachGoal','button_8');
                } else if (e.target.parentNode.textContent.includes('Через сколько дней покупатель может вылечить больное горло, используя Лоротрицин® - Алиум?')) {
                  ym(94832002,'reachGoal','button_9');
                } else if (e.target.parentNode.textContent.includes('Влияет ли местный антибиотик тиротрицин на микрофлору ЖКТ?')) {
                  ym(94832002,'reachGoal','button_10');
                }
            }

            let opens = gameBlock.querySelectorAll('.game-card.opened').length
            if (opens == gameCards.length) {
                tumbler.checked = true
            } else {
                tumbler.checked = false
            }
        })
    })
  }

});

